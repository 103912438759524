var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { attrs: { view: "hHh lpr fFf", id: "piattaforma-rami" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sta_caricando,
              expression: "sta_caricando",
            },
          ],
          staticClass:
            "text-center justify-center q-pa-md q-gutter-sm fixed-center",
          staticStyle: { "z-index": "1000" },
        },
        [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
        1
      ),
      _c(
        "q-page-container",
        { staticStyle: { "padding-left": "16px", "padding-right": "16px" } },
        [
          _c("br"),
          _c("div", { staticClass: "row justify-center" }, [
            _vm.isAby
              ? _c(
                  "div",
                  {
                    staticClass: "col-12 col-md-12",
                    attrs: { align: "center" },
                  },
                  [
                    _c("q-img", {
                      staticStyle: { width: "300px" },
                      attrs: { src: _vm.path_logo },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isAby
              ? _c(
                  "div",
                  {
                    staticClass: "col-12 col-md-12",
                    attrs: { align: "center" },
                  },
                  [_c("h3", [_vm._v(_vm._s(_vm.getRagioneSocialeUfficio))])]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-12 col-md-8" },
              [
                _c("br"),
                _c(
                  "q-toolbar",
                  { staticClass: "bg-primary text-white shadow-2" },
                  [
                    _c("q-toolbar-title", [
                      _c("div", { attrs: { align: "center" } }, [
                        _vm._v("BOX CONTRATTUALE"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "q-list",
                  { attrs: { bordered: "" } },
                  [
                    _c(
                      "q-item",
                      [
                        _c("q-item-label", { staticClass: "text-h5" }, [
                          _vm._v(_vm._s(_vm.getNominativoCliente)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c("q-item-label", [
                          _c("strong", [_vm._v("Contratto N.")]),
                          _vm._v(" " + _vm._s(_vm.getNumeroPreventivo())),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "IL MIO ASSICURATORE",
                    },
                    model: {
                      value: _vm.mio_assicuratore,
                      callback: function ($$v) {
                        _vm.mio_assicuratore = $$v
                      },
                      expression: "mio_assicuratore",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left font-size: 14px;",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                        },
                      },
                      [
                        _c(
                          "q-list",
                          { attrs: { bordered: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "", lines: "1" } },
                                      [_vm._v("IL MIO CONSULENTE PERSONALE")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(_vm._s(_vm.getIntermediario)),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "", lines: "1" } },
                                      [_vm._v("LA SUA SEZIONE RUI")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRUIIntermediario.numero_rui
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "", lines: "1" } },
                                      [_vm._v("TELEFONO")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRecapitiIntermediario.telefono
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "", lines: "1" } },
                                      [_vm._v("EMAIL")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRecapitiIntermediario.email
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "DOCUMENTI DA LEGGERE",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left text-h7",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                        },
                      },
                      [
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { bordered: "" } },
                          _vm._l(
                            _vm.documenti_allegati.documenti,
                            function (altro, index) {
                              return _c(
                                "q-item",
                                {
                                  directives: [
                                    { name: "ripple", rawName: "v-ripple" },
                                  ],
                                  key: index,
                                  staticClass: "q-mb-sm",
                                  staticStyle: {
                                    "background-color": "#ffffc0",
                                    "font-size": "18px",
                                  },
                                  attrs: { clickable: "" },
                                },
                                [
                                  _c(
                                    "q-item-section",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.ScaricaDocumento(
                                            altro.path,
                                            altro.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(altro.descrizione)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "q-item-section",
                                    { attrs: { avatar: "" } },
                                    [
                                      _c("q-avatar", {
                                        attrs: {
                                          color: "blue-grey",
                                          "text-color": "white",
                                          icon: "mdi-download",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "I MIEI CONTRATTI",
                    },
                    model: {
                      value: _vm.miei_contratti,
                      callback: function ($$v) {
                        _vm.miei_contratti = $$v
                      },
                      expression: "miei_contratti",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left text-h7",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                        },
                      },
                      [
                        _c("br"),
                        _vm._l(
                          _vm.elenco_contratti,
                          function (prodotto, index) {
                            return _c("div", { key: index }, [
                              _c(
                                "fieldset",
                                [
                                  _c("legend", [
                                    _vm._v(_vm._s(prodotto.label_prodotto)),
                                  ]),
                                  _c("br"),
                                  _c(
                                    "q-list",
                                    {
                                      staticStyle: { "font-size": "16px" },
                                      attrs: { bordered: "" },
                                    },
                                    [
                                      _c(
                                        "q-item",
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _c(
                                                "q-item-label",
                                                {
                                                  attrs: {
                                                    caption: "",
                                                    lines: "1",
                                                  },
                                                },
                                                [_vm._v("FRAZIONAMENTO")]
                                              ),
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    prodotto.frazionamento.toUpperCase()
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item",
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _c(
                                                "q-item-label",
                                                {
                                                  attrs: {
                                                    caption: "",
                                                    lines: "1",
                                                  },
                                                },
                                                [_vm._v("COMPAGNIA")]
                                              ),
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    prodotto.compagnia.replaceAll(
                                                      "_",
                                                      " "
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item",
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _c(
                                                "q-item-label",
                                                {
                                                  attrs: {
                                                    caption: "",
                                                    lines: "1",
                                                  },
                                                },
                                                [_vm._v("NUMERO POLIZZA")]
                                              ),
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    prodotto.numero_polizza
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                  _vm.documenti_contratti.length > 0
                                    ? _c(
                                        "q-list",
                                        {
                                          staticStyle: { "font-size": "16px" },
                                          attrs: { bordered: "" },
                                        },
                                        [
                                          _c(
                                            "q-item",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple",
                                                },
                                              ],
                                              staticClass: "q-mb-sm",
                                              staticStyle: {
                                                "background-color": "#ffffc0",
                                              },
                                              attrs: { clickable: "" },
                                            },
                                            [
                                              _c(
                                                "q-item-section",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.ScaricaDocumento(
                                                        _vm.documenti_contratti[
                                                          index
                                                        ].url,
                                                        _vm.documenti_contratti[
                                                          index
                                                        ].filename
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "q-item-label",
                                                    {
                                                      attrs: {
                                                        caption: "",
                                                        lines: "1",
                                                      },
                                                    },
                                                    [_vm._v("POLIZZA")]
                                                  ),
                                                  _c("q-item-label", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .documenti_contratti[
                                                            index
                                                          ].filename
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-section",
                                                { attrs: { avatar: "" } },
                                                [
                                                  _c("q-avatar", {
                                                    attrs: {
                                                      color: "blue-grey",
                                                      "text-color": "white",
                                                      icon: "mdi-download",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.documenti_catalogo[index]
                                              .documenti,
                                            function (
                                              documento_catalogo,
                                              indexCatalogo
                                            ) {
                                              return _c(
                                                "div",
                                                { key: indexCatalogo },
                                                [
                                                  _c(
                                                    "q-item",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "ripple",
                                                          rawName: "v-ripple",
                                                        },
                                                      ],
                                                      staticClass: "q-mb-sm",
                                                      staticStyle: {
                                                        "background-color":
                                                          "#ffffc0",
                                                      },
                                                      attrs: { clickable: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "q-item-section",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.ScaricaDocumento(
                                                                documento_catalogo.path,
                                                                _vm
                                                                  .documenti_contratti[
                                                                  index
                                                                ].filename
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("q-item-label", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  documento_catalogo.descrizione
                                                                )
                                                              ),
                                                            ]),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "q-item-section",
                                                        {
                                                          attrs: { avatar: "" },
                                                        },
                                                        [
                                                          _c("q-avatar", {
                                                            attrs: {
                                                              color:
                                                                "blue-grey",
                                                              "text-color":
                                                                "white",
                                                              icon: "mdi-download",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("br"),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "RESOCONTO ECONOMICO",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left text-h7",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                        },
                      },
                      [
                        _c("br"),
                        _c(
                          "table",
                          {
                            staticClass: "table table-bordered",
                            staticStyle: { "font-size": "18px" },
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("Settore di polizza")]),
                                _c("th", [_vm._v("Compagnia")]),
                                _c("th", [_vm._v("Numero contratto")]),
                                _c("th", [_vm._v("Premio Contratto")]),
                              ]),
                            ]),
                            _vm._l(
                              _vm.elenco_contratti,
                              function (prodotto, index) {
                                return _c("tbody", { key: index }, [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(_vm._s(prodotto.label_prodotto)),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(prodotto.compagnia)),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(prodotto.numero_polizza)),
                                    ]),
                                    _c("td", { attrs: { align: "right" } }, [
                                      _vm._v(
                                        _vm._s(prodotto.premio.toFixed(2)) +
                                          " €"
                                      ),
                                    ]),
                                  ]),
                                ])
                              }
                            ),
                            _c("tfoot", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "3", align: "right" } },
                                  [_vm._v("CONSULENZA TOTALE:")]
                                ),
                                _c("td", { attrs: { align: "right" } }, [
                                  _c(
                                    "strong",
                                    { staticStyle: { color: "#800000" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getConsulenzaTotale()) + " €"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "3", align: "right" } },
                                  [_c("strong", [_vm._v("TOTALE:")])]
                                ),
                                _c("td", { attrs: { align: "right" } }, [
                                  _c(
                                    "strong",
                                    { staticStyle: { color: "#800000" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getPremioTotale()) + " €"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }