import { render, staticRenderFns } from "./index.vue?vue&type=template&id=47dfb826&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=47dfb826&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47dfb826",
  null
  
)


import QLayout from 'quasar/src/components/layout/QLayout.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QSpinnerHourglass,QPageContainer,QImg,QToolbar,QToolbarTitle,QList,QItem,QItemLabel,QExpansionItem,QItemSection,QAvatar});qInstall(component, 'directives', {Ripple});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47dfb826')) {
      api.createRecord('47dfb826', component.options)
    } else {
      api.reload('47dfb826', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=47dfb826&scoped=true&", function () {
      api.rerender('47dfb826', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/BoxInformativo2/index.vue"
export default component.exports